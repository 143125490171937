import React, { useState, useRef, useEffect } from 'react';

import './styleSheet.css';
import presets from './presets.js';

function Slider({ onValueChange }) {
  const [clickPositions, setClickPositions] = useState([]);
  const [pointColors, setPointColors] = useState({});
  const [draggingPointId, setDraggingPointId] = useState(null);
  const [count, setCount] = useState(true);

  const [specular, setSpecular] = useState(0);
  const [specularPower, setSpecularPower] = useState(0);
  const [ambient, setAmbient] = useState(0);
  const [shade, setShade] = useState(0);
  const [diffuse, setDiffuse] = useState(0);

  //sets to get the height of the canvas
  const [svgheight, setSvgHeight] = useState(430);
  const [openComponent, setOpenComponent] = useState('transform');

  //lowest value in scalarOpacity and colorTransfer
  //there shouldn't be anything in the field lessthan the min value and more than max value
  //if the anchor points ar outsid the plotting div, its because of these values, and the transformation function

  const minValue = -1025;

  //max value for the scalarOpacity and colorTransfer

  //change these if you want to change the scalarValue, height will be adjusted accordingly with calculation
  const maxValue = 3072;

  const toggleComponent = componentName => {
    setOpenComponent(
      openComponent === componentName
        ? componentName === 'transform'
          ? 'advanced'
          : 'transform'
        : componentName
    );
  };

  //defaultpreset option maybe make  a custom one later
  const [selectedOption, setSelectedOption] = useState(
    'vtkMRMLVolumePropertyNode3'
  );

  const [preset, setPreset] = useState(
    presets.find(preset => preset.id === selectedOption)
  );

  const [newPreset, setNewPreset] = useState(
    presets.find(preset => preset.id === selectedOption)
  );

  const [showDeleteButton, setShowDeleteButton] = useState({
    show: false,
    pointId: null,
    position: { x: 0, y: 0 },
  });
  const [showTooltip, setShowTooltip] = useState({
    show: false,
    x: 0,
    y: 0,
    textX: '',
    textY: '',
  });

  //this is the function that plots/generates the anchor points and color details from the preset and plots on html plane

  //this takes value from the preset  and distributes to array for color and scalar values
  const loadAnchorsFromScalarOpacity = scalarOpacity => {
    const parts = scalarOpacity.split(' ');
    const numPairs = parseInt(parts.shift(), 10) / 2;
    let anchors = [];

    for (let i = 0; i < numPairs * 2; i += 2) {
      const yOriginal = parseFloat(parts[i]);
      const xNormalized = parseFloat(parts[i + 1]);
      const y = ((yOriginal - minValue) * svgheight) / (maxValue - minValue);
      const x = xNormalized * 200;
      anchors.push({ id: i / 2, x, y });
    }

    return anchors;
  };

  const updateFromPreset = () => {
    if (
      newPreset &&
      newPreset.scalarOpacity &&
      svgRef.current &&
      newPreset.colorTransfer
    ) {
      const colorTransfers = parseColorTransfer(newPreset.colorTransfer);

      const newPointColors = {};
      const anchors = loadAnchorsFromScalarOpacity(newPreset.scalarOpacity);

      setClickPositions(anchors);

      const maxIdLoaded = Math.max(...anchors.map(anchor => anchor.id), 0);
      maxIdRef.current = Math.max(maxIdRef.current, maxIdLoaded);
      anchors.forEach(point => {
        const closest = colorTransfers.reduce((prev, curr) => {
          const prevDiff = Math.abs(transformScalarToY(prev.scalar) - point.y);
          const currDiff = Math.abs(transformScalarToY(curr.scalar) - point.y);
          const chosen = currDiff < prevDiff ? curr : prev;

          return chosen;
        }, colorTransfers[0]);

        if (closest) {
          newPointColors[point.id] = rgbToHex(closest.r, closest.g, closest.b);
        }
      });

      setPointColors(newPointColors);

      // setPointColors(pointColors);
    }
  };

  useEffect(() => {
    updateFromPreset();

    // if (preset) {
    const colorTransfers = parseColorTransfer(preset.colorTransfer);

    setAmbient(preset.ambient);
    setSpecular(preset.specular);
    setSpecularPower(preset.specularPower);
    setShade(preset.shade);
    setDiffuse(preset.diffuse);

    const newPointColors = {};

    const anchors = loadAnchorsFromScalarOpacity(preset.scalarOpacity);

    setClickPositions(anchors);

    anchors.forEach(point => {
      const closest = colorTransfers.reduce((prev, curr) => {
        const prevDiff = Math.abs(transformScalarToY(prev.scalar) - point.y);
        const currDiff = Math.abs(transformScalarToY(curr.scalar) - point.y);
        const chosen = currDiff < prevDiff ? curr : prev;

        return chosen;
      }, colorTransfers[0]);

      if (closest) {
        newPointColors[point.id] = rgbToHex(closest.r, closest.g, closest.b);
      }
    });

    setPointColors(newPointColors);
    // }
  }, [preset]);

  useEffect(() => {
    updateFromPreset();
  }, [svgheight]);

  const colorInputRef = useRef(null);
  const svgRef = useRef(0);
  const maxIdRef = useRef(0);
  const testRef = useRef(0);

  const getNextId = () => {
    const newId = maxIdRef.current + 1;
    maxIdRef.current = newId;
    return newId;
  };

  // const handleDoubleClick = (e) => {
  //   e.stopPropagation();

  //   const rect = e.currentTarget.getBoundingClientRect();
  //   const x = e.clientX - rect.left - 25;

  //   const scrollTop = e.currentTarget.scrollTop;
  //   const y = e.clientY - rect.top - 25 + scrollTop;

  //   const adjustedX = Math.max(0, Math.min(x, 200));
  //   const adjustedY = Math.max(0, Math.min(y, svgheight));

  //   const newPoint = { id: getNextId(), x: adjustedX, y: adjustedY };

  //   setClickPositions([...clickPositions, newPoint]);
  //   setNewPreset((currentPreset) => ({
  //     ...currentPreset,
  //     scalarOpacity: scalarOpacity(),
  //     colorTransfer: constructColorTransferString(),
  //   }));
  // };
  const handleDoubleClick = e => {
    e.stopPropagation();

    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left - 25;
    const scrollTop = e.currentTarget.scrollTop;
    const y = e.clientY - rect.top - 25 + scrollTop;
    const adjustedX = Math.max(0, Math.min(x, 200));
    const adjustedY = Math.max(0, Math.min(y, svgheight));

    const newPoint = { id: getNextId(), x: adjustedX, y: adjustedY };

    // Update click positions state
    setClickPositions(prevPositions => [...prevPositions, newPoint]);

    // Immediately create the new preset object
    const updatedPreset = {
      ...newPreset, // Make sure newPreset is the current state captured in the closure
      scalarOpacity: scalarOpacity(), // Assuming this function uses the latest state
      colorTransfer: constructColorTransferString(), // Assuming this function uses the latest state
    };

    // Update the newPreset state
    setNewPreset(updatedPreset);

    // Call the parent component's callback function with the new preset
    onValueChange(updatedPreset);
  };

  const openColorPicker = (e, pointId) => {
    e.stopPropagation();
    const colorInput = colorInputRef.current;
    colorInput.style.position = 'absolute';
    colorInput.style.left = `${e.clientX}px`;
    colorInput.style.top = `${e.clientY}px`;
    colorInput.dataset.pointId = pointId;
    colorInput.click();
    e.target.blur();
  };

  const handleColorChange = e => {
    const pointId = e.target.dataset.pointId;
    const newColor = e.target.value;
    setPointColors(prevColors => ({ ...prevColors, [pointId]: newColor }));
    setNewPreset(currentPreset => ({
      ...currentPreset,
      scalarOpacity: scalarOpacity(),
      colorTransfer: constructColorTransferString(),
    }));
  };

  const handleMouseDown = pointId => e => {
    e.stopPropagation();
    setDraggingPointId(pointId);
  };

  const handleMouseMove = e => {
    if (draggingPointId !== null) {
      const rect = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - rect.left - 25;

      // Adjust Y coordinate to include the div's vertical scroll offset
      const scrollTop = e.currentTarget.scrollTop;
      const y = e.clientY - rect.top - 25 + scrollTop;

      const adjustedX = Math.max(0, Math.min(x, 200));
      const adjustedY = Math.max(0, Math.min(y, svgheight));

      const normalizedX = adjustedX / 200;
      const transformedY = transformYValue(adjustedY);

      const updatedPositions = clickPositions.map(pos =>
        pos.id === draggingPointId
          ? { ...pos, x: adjustedX, y: adjustedY }
          : pos
      );
      setClickPositions(updatedPositions);
      setNewPreset(currentPreset => ({
        ...currentPreset,
        scalarOpacity: scalarOpacity(),
        colorTransfer: constructColorTransferString(),
      }));

      // setShowTooltip({
      //   show: true,
      //   x: e.clientX,
      //   y: e.clientY + scrollTop - rect.top,
      //   textY: `Pixel: ${transformedY.toFixed(3)}`,
      //   textX: `Opacity: ${normalizedX.toFixed(6)}`,
      // });
      setShowTooltip({
        show: true,
        x: e.clientX,
        // y: (scrollTop > 0 ? e.clientY + scrollTop - rect.top : e.clientY) - 40,
        y: y - scrollTop + 5,
        textY: `Pixel: ${transformedY.toFixed(3)}`,
        textX: `Opacity: ${normalizedX.toFixed(6)}`,
      });
    }
  };

  const handleMouseUp = () => {
    setDraggingPointId(null);
  };

  const handleRightClick = (pointId, e) => {
    e.preventDefault();
    // Get the rectangle of the clicked marker relative to the viewport
    const rect = e.currentTarget.getBoundingClientRect();

    // Assuming scrollableDivRef is a ref to your scrollable parent div
    const scrollLeft = testRef.current.scrollLeft;
    const scrollTop = testRef.current.scrollTop;

    setShowDeleteButton({
      show: true,
      pointId,
      position: {
        // Calculate position by adding the left of the element, adjust with scrollLeft and additional offset
        x: rect.left + scrollLeft + 10, // 10 pixels offset from the cursor to the right
        // Calculate position by adding the top of the element, adjust with scrollTop and additional offset
        y: rect.top + scrollTop - 40, // 40 pixels above the cursor
      },
    });
  };

  const deletePoint = pointId => {
    setClickPositions(prevPositions =>
      prevPositions.filter(point => point.id !== pointId)
    );
    setShowDeleteButton({
      show: false,
      pointId: null,
      position: { x: 0, y: 0 },
    });
    setNewPreset(currentPreset => ({
      ...currentPreset,
      scalarOpacity: scalarOpacity(),
      colorTransfer: constructColorTransferString(),
    }));
    setCount(setCount + 1);
  };
  const handleMouseEnter = (x, y, e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const scrollTop = e.currentTarget.scrollTop;
    const normalizedX = x / 200;
    const transformedY = transformYValue(y);

    setShowTooltip({
      show: true,
      x: e.clientX,
      y: (scrollTop > 0 ? e.clientY + scrollTop - rect.top : e.clientY) - 150,
      // y: e.clientY + scrollTop - rect.top,
      textY: `Pixel: ${transformedY.toFixed(3)}`,
      textX: `Opacity: ${normalizedX.toFixed(6)}`,
    });
  };
  const transformYValue = y => {
    return ((y - 0) * (maxValue - minValue)) / (svgheight - 0) + minValue;
  };

  const transformScalarToY = scalar => {
    return ((scalar - minValue) * svgheight) / (maxValue - minValue);
  };

  const transformYToScalar = (y, svgheight) => {
    return (y * (maxValue - minValue)) / svgheight - 1025;
  };

  const handleMouseLeave = () => {
    setShowTooltip({ show: false, x: 0, y: 0, textX: '', textY: '' });
  };
  const handleCloseDelete = () => {
    setShowDeleteButton({
      show: false,
      pointId: null,
      position: { x: 0, y: 0 },
    });
  };
  const handleMouseLeaveContainer = () => {
    setShowTooltip({ show: false, x: 0, y: 0, textX: '', textY: '' });
    setDraggingPointId(null);
    setNewPreset(currentPreset => ({
      ...currentPreset,
      scalarOpacity: scalarOpacity(),
      colorTransfer: constructColorTransferString(),
    }));
  };

  const handleCtTransfer = e => {
    setSelectedOption(e.target.value);
    const preset = presets.find(preset => preset.id === e.target.value);
    setPreset(preset);
    setNewPreset(preset);
  };

  const sortedPositions = [...clickPositions].sort((a, b) => a.y - b.y);

  function rgbToHex(r, g, b) {
    const toHex = c => {
      const hex = Math.floor(c * 255).toString(16);
      return hex.length === 1 ? `0${hex}` : hex;
    };
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  }

  function hexToRgbNormalized(hex) {
    hex = hex.replace(/^#/, '');
    let r = parseInt(hex.substring(0, 2), 16) / 255;
    let g = parseInt(hex.substring(2, 4), 16) / 255;
    let b = parseInt(hex.substring(4, 6), 16) / 255;
    return { r, g, b };
  }

  const getRGBForPoint = pointId => {
    const hexColor = pointColors[pointId]; // Assuming pointColors is defined elsewhere

    if (!hexColor) {
      return { r: 0, g: 0, b: 0 };
    }

    let { r, g, b } = hexToRgbNormalized(hexColor);

    r = parseFloat(r.toFixed(6));
    g = parseFloat(g.toFixed(6));
    b = parseFloat(b.toFixed(6));

    return { r, g, b };
  };

  function parseColorTransfer(colorTransferString) {
    const parts = colorTransferString.split(' ').slice(1);

    const colorTransfers = [];
    for (let i = 0; i < parts.length; i += 4) {
      colorTransfers.push({
        scalar: parseFloat(parts[i]),
        r: parseFloat(parts[i + 1]),
        g: parseFloat(parts[i + 2]),
        b: parseFloat(parts[i + 3]),
      });
    }

    return colorTransfers;
  }
  const handleWheel = e => {
    // e.preventDefault(); // Prevent the default scroll behavior

    const zoomFactor = 20; // Determine how much to zoom with each scroll
    let newHeight = svgheight;

    if (e.deltaY > 0) {
      // Scroll down or up (Zoom in)
      newHeight += zoomFactor;
    } else if (e.deltaY < 0) {
      newHeight -= zoomFactor;
      if (newHeight < 500) {
        newHeight = 500;
      }
    }

    setSvgHeight(newHeight);
    setNewPreset(currentPreset => ({
      ...currentPreset,
      scalarOpacity: scalarOpacity(),
      colorTransfer: constructColorTransferString(),
    }));
  };

  const constructColorTransferString = () => {
    const parts = [];

    sortedPositions.forEach(pos => {
      const yTransformed = transformYValue(pos.y);
      const { r, g, b } = getRGBForPoint(pos.id);
      parts.push(yTransformed, r, g, b);
    });

    parts.unshift(parts.length);

    return parts.join(' ');
  };
  const scalarOpacity = () => {
    const pairs = sortedPositions
      .map(pos => {
        const yFormatted = transformYToScalar(pos.y, svgheight).toFixed(3);

        let xNormalized = pos.x / 200;
        const xFormatted =
          xNormalized === 1 || xNormalized === 0
            ? xNormalized.toString()
            : xNormalized.toFixed(6);

        return `${yFormatted} ${xFormatted}`;
      })
      .join(' ');
    return `${sortedPositions.length * 2} ${pairs}`;
  };

  //impliment useCallback  later
  useEffect(() => {
    const updatedPreset = {
      ...newPreset,
      scalarOpacity: scalarOpacity(),
      colorTransfer: constructColorTransferString(),
    };
    setNewPreset(updatedPreset);
  }, [clickPositions, pointColors]);
  useEffect(() => {
    onValueChange(newPreset);
  }, [
    newPreset,
    // pointColors,
    onValueChange,
    // clickPositions,
    // preset,
    // sortedPositions,
    // count,
  ]);

  // useEffect(() => {
  //   // console.log(newPreset);
  // }, [newPreset]);

  return (
    <div
      className="main-container"
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '250px',
      }}
    >
      <div
        className="selector-container"
        style={{ flex: '0 1 auto', padding: '2px' }}
      >
        <select
          className="select-field"
          value={selectedOption}
          onChange={e => {
            handleCtTransfer(e);
          }}
          style={{ width: '100%' }}
        >
          {presets.map(preset => (
            <option key={preset.id} value={preset.id}>
              {preset.name}
            </option>
          ))}
        </select>
      </div>
      <div className="tabsHeader">
        <div className="tabsContainer">
          <div
            className={`tab ${openComponent === 'transform' ? 'active' : ''}`}
            onClick={() => toggleComponent('transform')}
          >
            Transform
          </div>
          <div
            className={`tab ${openComponent === 'advanced' ? 'active' : ''}`}
            onClick={() => toggleComponent('advanced')}
          >
            Advanced
          </div>
        </div>
      </div>
      {/* <div onClick={() => toggleComponent("transform")}>
      Transform {openComponent === "transform" ? "∨" : "∧"}
    </div> */}
      {openComponent === 'transform' && (
        <div
          className="svg-container"
          style={{ flex: '1 1 auto', position: 'relative' }}
          // onWheel={handleWheel}
          ref={svgRef}
        >
          <div
            className="container_svg"
            style={{ height: '70vh' }}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onDoubleClick={handleDoubleClick}
            onMouseLeave={handleMouseLeaveContainer}
            onWheel={handleWheel}
            onClick={handleCloseDelete}
            ref={testRef}
          >
            <svg
              style={{
                width: '200px',
                height: `${svgheight}px`,
                position: 'absolute',
                top: '25px',
                left: '25px',
                pointerEvents: 'none',
              }}
            >
              {sortedPositions.length > 0 && (
                <path
                  d={sortedPositions
                    .map((p, i) => `${i === 0 ? 'M' : 'L'} ${p.x} ${p.y}`)
                    .join(' ')}
                  stroke="gray"
                  strokeWidth="1"
                  fill="none"
                />
              )}
            </svg>
            {sortedPositions.slice(1, -1).map(pos => (
              <div
                key={pos.id}
                className="marker"
                style={{
                  left: `${pos.x + 25 - 6.5}px`,
                  top: `${pos.y + 25 - 6.5}px`,
                  backgroundColor: pointColors[pos.id] || 'black',
                  border: '1px solid #ffffff',
                }}
                onMouseDown={handleMouseDown(pos.id)}
                onDoubleClick={e => openColorPicker(e, pos.id)}
                onContextMenu={e => handleRightClick(pos.id, e)}
                onMouseEnter={e => handleMouseEnter(pos.x, pos.y, e)}
                onMouseLeave={handleMouseLeave}
              />
            ))}
            {showDeleteButton.show && (
              <button
                style={{
                  position: 'absolute',
                  left: `${showDeleteButton.position.x}px`,
                  //-150 is the toolbar and logo div width
                  top: `${showDeleteButton.position.y - 150}px`,
                  color: 'red',
                  zIndex: 1000,
                }}
                onClick={() => {
                  deletePoint(showDeleteButton.pointId);
                }}
              >
                Delete
              </button>
            )}

            <input
              type="color"
              ref={colorInputRef}
              onChange={handleColorChange}
              style={{ display: 'none' }}
              data-point-id=""
            />
          </div>
          {showTooltip.show && (
            <div
              style={{
                position: 'absolute',
                left: `${showTooltip.x + 1}px`,
                top: `${showTooltip.y + 1}px`,
                backgroundColor: 'white',
                padding: '5px',
                border: '1px solid black',
                pointerEvents: 'none',
                zIndex: 1000,
                whiteSpace: 'nowrap',
              }}
            >
              <div>{showTooltip.textY}</div>
              <div>{showTooltip.textX}</div>
            </div>
          )}
        </div>
      )}
      {/* <div onClick={() => toggleComponent("advanced")}>
      {" "}
      Advanced {openComponent === "advanced" ? "∨" : "∧"}
    </div> */}
      {openComponent === 'advanced' && (
        <div
          style={{ width: '230px', padding: '10px' }}
          className="slider-container"
        >
          Specular
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={specular}
            className="slider"
            id="specular"
            onChange={e => {
              const updatedValue = parseFloat(e.target.value);
              setSpecular(updatedValue);
              setNewPreset(currentPreset => ({
                ...currentPreset,
                specular: updatedValue,
              }));
            }}
          />
          Specular Power
          <input
            type="range"
            min="0"
            max="10"
            value={specularPower}
            className="slider"
            id="specularPower"
            onChange={e => {
              const updatedValue = parseFloat(e.target.value);
              setSpecularPower(updatedValue);
              setNewPreset(currentPreset => ({
                ...currentPreset,
                specularPower: updatedValue,
              }));
            }}
          />
          Ambient
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={ambient}
            className="slider"
            id="ambient"
            onChange={e => {
              const updatedValue = parseFloat(e.target.value);
              setAmbient(updatedValue);
              setNewPreset(currentPreset => ({
                ...currentPreset,
                ambient: updatedValue,
              }));
            }}
          />
          Shade
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={shade}
            className="slider"
            id="shade"
            onChange={e => {
              const updatedValue = parseFloat(e.target.value);
              setShade(updatedValue);
              setNewPreset(currentPreset => ({
                ...currentPreset,
                shade: updatedValue,
              }));
            }}
          />
          Diffuse
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={diffuse}
            className="slider"
            id="diffuse"
            onChange={e => {
              const updatedValue = parseFloat(e.target.value);
              setDiffuse(updatedValue);
              setNewPreset(currentPreset => ({
                ...currentPreset,
                diffuse: updatedValue,
              }));
            }}
          />
        </div>
      )}
    </div>
  );
}

export default Slider;
