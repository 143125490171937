import cornerstoneMath from 'cornerstone-math';
import cornerstone from 'cornerstone-core';
import { importInternal } from 'cornerstone-tools';

const pointInsideBoundingBox = importInternal('util/pointInsideBoundingBox');
const getProximityThreshold = importInternal('util/getProximityThreshold');

const pointNearPerpendicular = (
  element,
  handles,
  coords,
  distanceThreshold
) => {
  // const cornerstone = external.cornerstone;
  const lineSegment = {
    start: cornerstone.pixelToCanvas(element, handles.perpendicularStart),
    end: cornerstone.pixelToCanvas(element, handles.perpendicularEnd),
  };

  const distanceToPoint = cornerstoneMath.lineSegment.distanceToPoint(
    lineSegment,
    coords
  );

  return distanceToPoint < distanceThreshold;
};

export default function(element, data, coords, interactionType = 'mouse') {
  // const cornerstone = external.cornerstone;
  const { handles } = data;
  const lineSegment = {
    start: cornerstone.pixelToCanvas(element, handles.start),
    end: cornerstone.pixelToCanvas(element, handles.end),
  };

  const distanceToPoint = cornerstoneMath.lineSegment.distanceToPoint(
    lineSegment,
    coords
  );

  if (pointInsideBoundingBox(handles.textBox, coords)) {
    return true;
  }

  const distanceThreshold = getProximityThreshold(interactionType, 'VHS');

  if (pointNearPerpendicular(element, handles, coords, distanceThreshold)) {
    return true;
  }

  return distanceToPoint < distanceThreshold;
}
