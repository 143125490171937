import React, { useState } from 'react';
import { Component } from 'react';
import { getImageData, loadImageData, View3D } from 'react-vtkjs-viewport';
import vtkVolume from 'vtk.js/Sources/Rendering/Core/Volume';
import vtkVolumeMapper from 'vtk.js/Sources/Rendering/Core/VolumeMapper';
import { api } from 'dicomweb-client';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
// import './initCornerstone.js';
import vtkColorTransferFunction from 'vtk.js/Sources/Rendering/Core/ColorTransferFunction';
import vtkPiecewiseFunction from 'vtk.js/Sources/Common/DataModel/PiecewiseFunction';
import vtkColorMaps from 'vtk.js/Sources/Rendering/Core/ColorTransferFunction/ColorMaps';
import presets from './presets.js';
import OHIF from '@ohif/core';
const { studyMetadataManager } = OHIF.utils;
import './VTKViewport.css';
import './styleSheet.css';
import Slider from './slider.js';

// import testHTML from './test.html';

//what chaged?? used props to send Uids

window.cornerstoneWADOImageLoader = cornerstoneWADOImageLoader;
let isEnabled = true;
var heightBackup = '';
var htmlBackup = '';

// const url = '/dicom-web';
const storedState = sessionStorage.getItem('state');
let originalToolbarHTML = null; // To store the original innerHTML
var originalToolbarHeight = null;
// const [preset, setPreset] = useState('');

// Parse the stored JSON string into a JavaScript object

// Access the desired value with conditional checks

// Assign the value to the const url
const url = window.config.servers.dicomWeb[0].wadoRoot || '/dicom-web';

// const studyInstanceUID =
//   '1.3.6.1.4.1.14519.5.2.1.256467663913010332776401703474716742458';
// const ctSeriesInstanceUID =
//   '1.3.6.1.4.1.14519.5.2.1.40445112212390159711541259681923198035';

function createActorMapper(imageData) {
  const mapper = vtkVolumeMapper.newInstance();
  mapper.setInputData(imageData);

  const actor = vtkVolume.newInstance();
  actor.setMapper(mapper);

  return {
    actor,
    mapper,
  };
}

function getShiftRange(colorTransferArray) {
  // Credit to paraview-glance
  // https://github.com/Kitware/paraview-glance/blob/3fec8eeff31e9c19ad5b6bff8e7159bd745e2ba9/src/components/controls/ColorBy/script.js#L133

  // shift range is original rgb/opacity range centered around 0
  let min = Infinity;
  let max = -Infinity;
  for (let i = 0; i < colorTransferArray.length; i += 4) {
    min = Math.min(min, colorTransferArray[i]);
    max = Math.max(max, colorTransferArray[i]);
  }

  const center = (max - min) / 2;

  return {
    shiftRange: [-center, center],
    min,
    max,
  };
}

function applyPointsToPiecewiseFunction(points, range, pwf) {
  const width = range[1] - range[0];
  const rescaled = points.map(([x, y]) => [x * width + range[0], y]);

  pwf.removeAllPoints();
  rescaled.forEach(([x, y]) => pwf.addPoint(x, y));

  return rescaled;
}

function applyPointsToRGBFunction(points, range, cfun) {
  const width = range[1] - range[0];
  const rescaled = points.map(([x, r, g, b]) => [
    x * width + range[0],
    r,
    g,
    b,
  ]);

  cfun.removeAllPoints();
  rescaled.forEach(([x, r, g, b]) => cfun.addRGBPoint(x, r, g, b));

  return rescaled;
}

function applyPreset(actor, preset) {
  // Create color transfer function
  const colorTransferArray = preset.colorTransfer
    .split(' ')
    .splice(1)
    .map(parseFloat);

  const { shiftRange } = getShiftRange(colorTransferArray);
  let min = shiftRange[0];
  const width = shiftRange[1] - shiftRange[0];
  const cfun = vtkColorTransferFunction.newInstance();
  const normColorTransferValuePoints = [];
  for (let i = 0; i < colorTransferArray.length; i += 4) {
    let value = colorTransferArray[i];
    const r = colorTransferArray[i + 1];
    const g = colorTransferArray[i + 2];
    const b = colorTransferArray[i + 3];

    value = (value - min) / width;
    normColorTransferValuePoints.push([value, r, g, b]);
  }

  applyPointsToRGBFunction(normColorTransferValuePoints, shiftRange, cfun);

  actor.getProperty().setRGBTransferFunction(0, cfun);

  // Create scalar opacity function
  const scalarOpacityArray = preset.scalarOpacity
    .split(' ')
    .splice(1)
    .map(parseFloat);

  const ofun = vtkPiecewiseFunction.newInstance();
  const normPoints = [];
  for (let i = 0; i < scalarOpacityArray.length; i += 2) {
    let value = scalarOpacityArray[i];
    const opacity = scalarOpacityArray[i + 1];

    value = (value - min) / width;

    normPoints.push([value, opacity]);
  }

  applyPointsToPiecewiseFunction(normPoints, shiftRange, ofun);

  actor.getProperty().setScalarOpacity(0, ofun);

  const [
    gradientMinValue,
    gradientMinOpacity,
    gradientMaxValue,
    gradientMaxOpacity,
  ] = preset.gradientOpacity
    .split(' ')
    .splice(1)
    .map(parseFloat);

  actor.getProperty().setUseGradientOpacity(0, true);
  actor.getProperty().setGradientOpacityMinimumValue(0, gradientMinValue);
  actor.getProperty().setGradientOpacityMinimumOpacity(0, gradientMinOpacity);
  actor.getProperty().setGradientOpacityMaximumValue(0, gradientMaxValue);
  actor.getProperty().setGradientOpacityMaximumOpacity(0, gradientMaxOpacity);

  if (preset.interpolation === '1') {
    actor.getProperty().setInterpolationTypeToFastLinear();
    //actor.getProperty().setInterpolationTypeToLinear()
  }

  const ambient = parseFloat(preset.ambient);
  //const shade = preset.shade === '1'
  const diffuse = parseFloat(preset.diffuse);
  const specular = parseFloat(preset.specular);
  const specularPower = parseFloat(preset.specularPower);

  //actor.getProperty().setShade(shade)
  actor.getProperty().setAmbient(ambient);
  actor.getProperty().setDiffuse(diffuse);
  actor.getProperty().setSpecular(specular);
  actor.getProperty().setSpecularPower(specularPower);
}

function createCT3dPipeline(imageData, ctTransferFunctionPresetId) {
  const { actor, mapper } = createActorMapper(imageData);

  const sampleDistance =
    1.2 *
    Math.sqrt(
      imageData
        .getSpacing()
        .map(v => v * v)
        .reduce((a, b) => a + b, 0)
    );

  const range = imageData
    .getPointData()
    .getScalars()
    .getRange();
  actor
    .getProperty()
    .getRGBTransferFunction(0)
    .setRange(range[0], range[1]);

  mapper.setSampleDistance(sampleDistance);

  const preset = presets.find(
    preset => preset.id === ctTransferFunctionPresetId
  );
  applyPreset(actor, preset);

  actor.getProperty().setScalarOpacityUnitDistance(0, 2.5);

  return actor;
}
// Select the button with the class name "toolbar-button-label"

function createStudyImageIds(baseUrl, studySearchOptions) {
  const SOP_INSTANCE_UID = '00080018';
  const SERIES_INSTANCE_UID = '0020000E';

  const client = new api.DICOMwebClient({ url });
  const study = studyMetadataManager.get(studySearchOptions.studyInstanceUID);
  const displaySets = study.getDisplaySets();
  let instancesNew = window.window.cornerstone.imageCache.cachedImages;
  const imageIds1 = instancesNew.map(metaData => {
    const imageId1 = metaData.imageId;

    return imageId1;
  });
  return imageIds1;

  // return new Promise((resolve, reject) => {
  //   client.retrieveStudyMetadata(studySearchOptions).then(instances => {
  //     console.log(window.window.cornerstone.imageCache.cachedImages);
  //     let instancesNew = window.window.cornerstone.imageCache.cachedImages;
  //     console.log(instances);
  //     console.log(cornerstoneWADOImageLoader);

  //     const imageIds = instances.map(metaData => {
  //       const imageId =
  //         `wadors:` +
  //         baseUrl +
  //         '/studies/' +
  //         studySearchOptions.studyInstanceUID +
  //         '/series/' +
  //         metaData[SERIES_INSTANCE_UID].Value[0] +
  //         '/instances/' +
  //         metaData[SOP_INSTANCE_UID].Value[0] +
  //         '/frames/1';

  //       cornerstoneWADOImageLoader.wadors.metaDataManager.add(
  //         imageId,
  //         metaData
  //       );

  //       return imageId;
  //     });
  //     const imageIds1 = instancesNew.map(metaData => {
  //       console.log(metaData);
  //       const imageId1 = metaData.imageId;

  //       return imageId1;
  //     });
  //     console.log(imageIds);
  //     console.log(imageIds1, typeof imageIds1);

  //     resolve(imageIds);
  //   }, reject);
  // });
}

class VTKFusionExample extends Component {
  state = {
    volumeRenderingVolumes: null,
    ctTransferFunctionPresetId: 'vtkMRMLVolumePropertyNode4',
    preset: '',
    petColorMapId: 'hsv',
    StudyInstanceUID: this.props.StudyInstanceUID,
    SeriesInstanceUID: this.props.SeriesInstanceUID,
    WindowHeight: this.props.WindowHeight,
    WindowWidth: this.props.WindowWidth,
  };

  async componentDidMount() {
    const studyInstanceUID = this.state.StudyInstanceUID;

    const searchInstanceOptions = {
      studyInstanceUID,
    };
    const imageIdPromise = createStudyImageIds(url, searchInstanceOptions);

    this.apis = [];

    const imageIds = await imageIdPromise;
    let ctImageIds = imageIds.filter(imageId =>
      imageId.includes(this.state.SeriesInstanceUID)
    );

    //ctImageIds = ctImageIds.slice(0, ctImageIds.length / 2);

    const ctImageDataObject = this.loadDataset(ctImageIds, 'ctDisplaySet');

    const ctImageData = ctImageDataObject.vtkImageData;
    const ctVolVR = createCT3dPipeline(
      ctImageData,
      this.state.ctTransferFunctionPresetId
    );

    this.setState({
      volumeRenderingVolumes: [ctVolVR],
      percentComplete: 0,
    });
  }

  saveApiReference = api => {
    this.apis = [api];
  };
  handleValueChange = updatedPreset => {
    // this.preset = updatedPreset;
    const actor = this.state.volumeRenderingVolumes[0];

    applyPreset(actor, updatedPreset);

    this.rerenderAll();

    // this.setState({
    //   ctTransferFunctionPresetId,
    // });
    // setPreset(updatedPreset);
    // Any additional actions to take when the preset is updated
  };

  modifyToolbar(activated) {
    if (activated && isEnabled) {
      var roundedButton = document.querySelector('.roundedButton');
      roundedButton.click();
      const toolbar = document.getElementsByClassName('ToolbarRow');

      // Save original values if not already saved
      if (originalToolbarHTML === null) {
        originalToolbarHTML = toolbar[0].innerHTML;
      }
      if (originalToolbarHeight === null) {
        originalToolbarHeight = toolbar[0].style.height;
      }

      // Modify toolbar
      toolbar[0].innerHTML =
        '<div style="color: white;">3D Volume Rendering</div>';
      toolbar[0].style.height = '50px';
    }
  }

  resetToolbar() {
    const toolbar = document.getElementsByClassName('ToolbarRow');

    // Only reset if original values are saved
    if (originalToolbarHTML !== null && originalToolbarHeight !== null) {
      toolbar[0].innerHTML = originalToolbarHTML;
      toolbar[0].style.height = originalToolbarHeight;

      // Optionally, clear the original values after resetting
      originalToolbarHTML = null;
      originalToolbarHeight = null;
    }
  }

  handleChangeCTTransferFunction = event => {
    const ctTransferFunctionPresetId = event.target.value;
    const preset = presets.find(
      preset => preset.id === ctTransferFunctionPresetId
    );

    const actor = this.state.volumeRenderingVolumes[0];

    applyPreset(actor, preset);

    this.rerenderAll();

    this.setState({
      ctTransferFunctionPresetId,
    });
  };

  rerenderAll = () => {
    // Update all render windows, since the automatic re-render might not
    // happen if the viewport is not currently using the painting widget
    Object.keys(this.apis).forEach(viewportIndex => {
      const renderWindow = this.apis[
        viewportIndex
      ].genericRenderWindow.getRenderWindow();

      renderWindow.render();
    });
  };

  loadDataset(imageIds, displaySetInstanceUid) {
    const imageDataObject = getImageData(imageIds, displaySetInstanceUid);
    loadImageData(imageDataObject);

    const numberOfFrames = imageIds.length;

    const onPixelDataInsertedCallback = numberProcessed => {
      const percentComplete = Math.floor(
        (numberProcessed * 100) / numberOfFrames
      );

      if (this.state.percentComplete !== percentComplete) {
        this.setState({ percentComplete });
      }

      if (percentComplete % 20 === 0) {
        this.rerenderAll();
      }
    };

    const onAllPixelDataInsertedCallback = () => {
      this.rerenderAll();
    };

    imageDataObject.onPixelDataInserted(onPixelDataInsertedCallback);
    imageDataObject.onAllPixelDataInserted(onAllPixelDataInsertedCallback);

    return imageDataObject;
  }

  render() {
    if (!this.state.volumeRenderingVolumes) {
      return <h4>Loading...</h4>;
    }

    const ctTransferFunctionPresetOptions = presets.map(preset => {
      return (
        <option key={preset.id} value={preset.id}>
          {preset.name}
        </option>
      );
    });

    const { percentComplete } = this.state;

    const progressString = `Progress: ${percentComplete}%`;
    const squareStyle = {
      // height: `${this.state.WindowHeight - 35}px`,
      height: '70vh',
      width: '99.15%',
    };

    return (
      <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
        <div style={{ width: '250px', height: '100%' }}>
          <Slider onValueChange={this.handleValueChange} />
        </div>
        <div div style={{ flexGrow: 1 }}>
          <View3D
            volumes={this.state.volumeRenderingVolumes}
            onCreated={this.saveApiReference}
          />
        </div>
      </div>
    );
  }

  static buttons(activated) {
    if (isEnabled) {
      // let textToButton;
      // let state;
      if (activated) {
        // textToButton = 'Exit 2D MPR';
        // state = 'visible';
        var roundedButton = document.querySelector('.roundedButton');
        roundedButton.click();
      } else {
        //   textToButton = 'Exit 3D';
        //   state = 'hidden';
        // }

        // Hide the original toolbar
        const toolbar = document.getElementsByClassName('ToolbarRow');
        toolbar[0].style.visibility = 'hidden';
        toolbar[0].style.height = '0';

        // Create a new container for the div and button
        const newToolbar = document.createElement('div');
        newToolbar.style.height = '50px'; // Set the height for the new toolbar
        newToolbar.innerHTML =
          '<div style="display: flex; justify-content: center; align-items: center; color: white; width: 100%;">' +
          '<div style="flex-grow: 1; text-align: center;">3D Volume Rendering</div>' +
          '<button id="newRollbackButton" style="margin-left: auto; background: none; border: 2px solid #3c5d80; color: #20a5d6; cursor: pointer; padding: 5px; font-size: inherit;">Exit 3D</button>' +
          '</div>';

        // Append the new container to the same parent as the original toolbar
        toolbar[0].parentNode.insertBefore(newToolbar, toolbar[0]);

        // Add click event listener to the new button
        const newRollbackButton = document.getElementById('newRollbackButton');
        if (newRollbackButton) {
          newRollbackButton.addEventListener('click', function() {
            ohif.app.commandsManager.runCommand('setCornerstoneLayout');
            // Optionally make the original toolbar visible again when needed
            toolbar[0].style.visibility = 'visible';
            newToolbar.parentNode.removeChild(newToolbar);
            toolbar[0].style.height = ''; // Restore original height
            var roundedButton = document.querySelector('.roundedButton');
            roundedButton.click();
          });
        }

        // for (let i = 0; i < 10; i++) {
        //   if (i === 2) {
        //     continue;
        //   }
        //   let element = toolbar[0].children[i];
        //   element.style.visibility = state;
        // }
        // toolbar[0].children[2].children[1].innerText = textToButton;
        // console.clear();
      }
    }
  }
}

export default VTKFusionExample;
