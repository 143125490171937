import csTools from 'cornerstone-tools';
import {
  importInternal,
  getToolState,
  toolColors,
  removeToolState,
  addToolState,
} from 'cornerstone-tools';
import cornerstoneMath from 'cornerstone-math';
import cornerstone from 'cornerstone-core';
const draw = importInternal('drawing/draw');
const drawCircle = importInternal('drawing/drawCircle');
const BaseAnnotationTool = importInternal('base/BaseAnnotationTool');

const throttle = importInternal('util/throttle');
const textStyle = csTools.textStyle;
const toolStyle = csTools.toolStyle;
const moveNewHandle = importInternal('manipulators/moveNewHandle');
const getNewContext = importInternal('drawing/getNewContext');
const setShadow = importInternal('drawing/setShadow');
const drawLine = importInternal('drawing/drawLine');
const drawHandles = importInternal('drawing/drawHandles');
const drawLinkedTextBox = importInternal('drawing/drawLinkedTextBox');
const lineSegDistance = importInternal('util/lineSegDistance');
const roundToDecimal = importInternal('util/roundToDecimal');
const EVENTS = csTools.EVENTS;
const MouseCursor = importInternal('tools/cursors/MouseCursor');
const triggerEvent = importInternal('util/triggerEvent');
const getPixelSpacing = importInternal('util/getPixelSpacing');

/**
 * @public
 * @class TPLOTool
 * @memberof Tools.Annotation
 * @classdesc Tool for measuring TPLO.
 * @extends Tools.Base.BaseAnnotationTool
 */
export default class TPLOTool extends BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: 'TPLO',
      supportedInteractionTypes: ['Mouse'],
      // svgCursor: MouseCursor,
      configuration: {
        drawHandles: true,
        drawHandlesOnHover: false,
        hideHandlesIfMoving: false,
        renderDashed: false,
      },
    };

    super(props, defaultProps);

    this.hasIncomplete = false;

    this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 110);
  }

  createNewMeasurement(eventData) {
    // Create the measurement data for this tool with the end handle activated
    //why??
    this.hasIncomplete = true;

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      complete: false,
      value: '',
      handles: {
        start: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        end: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
        start2: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
          // drawnIndependently: true,
        },
        end2: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
          // drawnIndependently: true,
        },
        start3: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },

        end3: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
          // drawnIndependently: true,
        },
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
    };
  }

  /**
   *
   *
   * @param {*} element
   * @param {*} data
   * @param {*} coords
   * @returns {Boolean}
   */
  pointNearTool(element, data, coords) {
    if (data.visible === false) {
      return false;
    }

    if (this.hasIncomplete) {
      return false;
    }

    const seg1Near =
      lineSegDistance(element, data.handles.start, data.handles.end, coords) <
      25;
    const seg2Near =
      lineSegDistance(element, data.handles.start2, data.handles.end2, coords) <
      25;

    const seg3Near =
      lineSegDistance(element, data.handles.start3, data.handles.end3, coords) <
      25;

    return seg1Near || seg2Near || seg3Near;
  }

  updateCachedStats(image, element, data) {
    if (data.handles.diamStart) {
      const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

      const dx1 = data.handles.diamStart.x - data.handles.diamEnd.x;
      const dy1 = data.handles.diamStart.y - data.handles.diamEnd.y;
      const dx2 = data.handles.start3.x - data.handles.end3.x;
      const dy2 = data.handles.start3.y - data.handles.end3.y;

      const pixelSpacing = colPixelSpacing || 1;

      let angle =
        Math.acos(
          Math.abs(
            (dx1 * dx2 + dy1 * dy2) /
              (Math.sqrt(dx1 * dx1 + dy1 * dy1) *
                Math.sqrt(dx2 * dx2 + dy2 * dy2))
          )
        ) *
        (180 / Math.PI);

      data.rAngle = roundToDecimal(angle, 2);

      const dx =
        (data.handles.diamEnd.x - data.handles.diamStart.x) * pixelSpacing;
      const dy =
        (data.handles.diamEnd.y - data.handles.diamStart.y) *
        (rowPixelSpacing || 1);

      data.diameter = Math.sqrt(dx * dx + dy * dy);
      data.invalidated = false;
    }
  }

  renderToolData(evt) {
    const eventData = evt.detail;
    const {
      handleRadius,
      drawHandlesOnHover,
      hideHandlesIfMoving,
      renderDashed,
    } = this.configuration;
    // If we have no toolData for this element, return immediately as there is nothing to do
    const toolData = getToolState(evt.currentTarget, this.name);

    if (!toolData) {
      return;
    }
    const getDistance = cornerstoneMath.point.distance;

    // We have tool data for this element - iterate over each one and draw it
    const context = getNewContext(eventData.canvasContext.canvas);

    const lineWidth = toolStyle.getToolWidth();
    const lineDash = csTools.getModule('globalConfiguration').configuration
      .lineDash;
    const font = textStyle.getFont();
    const { element } = evt.detail;
    const image = cornerstone.getEnabledElement(element).image;
    const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];

      if (data.visible === false) {
        continue;
      }

      if (!data.value) {
        data.value = this.textBoxText(data, rowPixelSpacing, colPixelSpacing);
      }

      //draw function

      draw(context, context => {
        setShadow(context, this.configuration);

        //to convert to Canvas unit/point/value
        const startCanvas = cornerstone.pixelToCanvas(
          element,
          data.handles.start
        );

        const endCanvas = cornerstone.pixelToCanvas(element, data.handles.end);

        //inbuilt function

        const radius = getDistance(startCanvas, endCanvas);

        // Differentiate the color of activation tool
        const color = toolColors.getColorIfActive(data);

        const lineOptions = { color };
        const circleOptions = { color };
        const line1 = {};
        const line2 = {};
        let intersection;

        if (renderDashed) {
          lineOptions.lineDash = lineDash;
          circleOptions.lineDash = lineDash;
        }

        const optionsDashedLine = {
          color: '#20a5d6',
          lineWidth,
          lineDash: [10, 10],
        };

        //draws circle if provided with cener coordinates and radius

        drawCircle(
          context,
          element,
          data.handles.start,
          radius,
          circleOptions,
          'pixel'
        );

        drawLine(
          context,
          eventData.element,
          data.handles.start,
          data.handles.end2,
          lineOptions
        );
        drawLine(
          context,
          eventData.element,
          data.handles.start3,
          data.handles.end3,
          lineOptions
        );

        [line1.start, line1.end] = [
          { x: data.handles.start2.x, y: data.handles.start2.y },
          { x: data.handles.end2.x, y: data.handles.end2.y },
        ];

        [line2.start, line2.end] = [
          { x: data.handles.start3.x, y: data.handles.start3.y },
          { x: data.handles.end3.x, y: data.handles.end3.y },
        ];

        //find intersection point of two lines, returns null/undefined if not intersecting

        intersection = cornerstoneMath.lineSegment.intersectLine(line1, line2);

        //more cleanup needed
        if (intersection) {
          data.isIntersected = true;
        } else {
          data.isIntersected = false;
        }

        if (data.isIntersected) {
          var distanceStart3 = getDistance(data.handles.start3, intersection);
          var distanceEnd3 = getDistance(data.handles.end3, intersection);

          var furthestPoint = 0;
          if (!isNaN(distanceStart3) && !isNaN(distanceEnd3)) {
            if (distanceStart3 > distanceEnd3) {
              furthestPoint = data.handles.start3;
            } else {
              furthestPoint = data.handles.end3;
            }
          } else if (!isNaN(distanceStart3)) {
            furthestPoint = data.handles.start3;
          } else if (!isNaN(distanceEnd3)) {
            furthestPoint = data.handles.end3;
          } else {
            console.log('Invalid distance value.');
          }
          const startCanvas1 = cornerstone.pixelToCanvas(
            element,
            furthestPoint
          );

          const endCanvas1 = cornerstone.pixelToCanvas(element, intersection);
          let finalCircleRadius = getDistance(startCanvas1, endCanvas1);
          let radius1 = getDistance(furthestPoint, intersection);
          drawCircle(
            context,
            eventData.element,
            intersection,
            finalCircleRadius,
            optionsDashedLine
          );

          if (radius1) {
            const slope =
              (line1.end.y - line1.start.y) / (line1.end.x - line1.start.x);
            const perpendicular_slope = -1 / slope;
            const delta_x = radius1 / Math.sqrt(1 + perpendicular_slope ** 2);
            const delta_y = perpendicular_slope * delta_x;
            const end1 = {
              x: intersection.x + delta_x,
              y: intersection.y + delta_y,
            };
            const end2 = {
              x: intersection.x - delta_x,
              y: intersection.y - delta_y,
            };

            drawLine(context, eventData.element, end1, end2, optionsDashedLine);

            data.handles.diamStart = end1;
            data.handles.diamEnd = end2;
          }
        }

        const handleOptions = {
          color,
          handleRadius,
          drawHandlesIfActive: drawHandlesOnHover,
          hideHandlesIfMoving,
        };

        if (this.configuration.drawHandles) {
          const editedData = JSON.parse(JSON.stringify(data));
          delete editedData.handles.diamStart;
          delete editedData.handles.diamEnd;
          drawHandles(context, eventData, editedData.handles, handleOptions);
        }

        // Draw the textbox conaining annotation and measurement
        context.fillStyle = color;
        if (data.diameter) {
          const text = data.value;

          if (!data.handles.textBox.hasMoved) {
            const textCoords = {
              x: data.handles.diamStart.x,
              y: data.handles.diamStart.y - 10,
            };

            context.font = font;
            data.handles.textBox.x = textCoords.x;
            data.handles.textBox.y = textCoords.y;
          }

          if (data.isIntersected) {
            drawLinkedTextBox(
              context,
              eventData.element,
              data.handles.textBox,
              text,
              data.handles,
              textBoxAnchorPoints,
              color,
              lineWidth,
              0,
              true
            );
          }
        }
      });
    }
    function textBoxAnchorPoints(handles) {
      return [handles.diamEnd, handles.diamStart];
    }
  }

  getIncomplete(element) {
    const toolState = getToolState(element, this.name);

    if (toolState && Array.isArray(toolState.data)) {
      return toolState.data.find(({ complete }) => complete === false);
    }
  }

  addNewMeasurement(evt, interactionType) {
    const getDistance = cornerstoneMath.point.distance;
    evt.preventDefault();
    evt.stopPropagation();

    const eventData = evt.detail;

    let measurementData;
    let toMoveHandle;
    let doneMovingCallback = success => {
      // DoneMovingCallback for first measurement.
      if (!success) {
        removeToolState(element, this.name, measurementData);

        return;
      }
      const eventType = EVENTS.MEASUREMENT_COMPLETED;
      const eventData = {
        toolName: this.name,
        toolType: this.name, // Deprecation notice: toolType will be replaced by toolName
        element,
        measurementData,
      };

      triggerEvent(element, eventType, eventData);
    };

    // Search for incomplete measurements
    const element = evt.detail.element;
    const pendingMeasurement = this.getIncomplete(element);

    //have to fix some stuff

    if (pendingMeasurement) {
      measurementData = pendingMeasurement;
      measurementData.complete = true;
      // measurementData.handles.start2 = {
      //   x: eventData.currentPoints.image.x,
      //   y: eventData.currentPoints.image.y,
      //   drawnIndependently: true,
      //   highlight: true,
      //   active: false,
      // };

      measurementData.handles.end2 = {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        drawnIndependently: false,
        highlight: true,
        active: true,
      };
      var lineLeng = getDistance(
        measurementData.handles.start2,
        measurementData.handles.end2
      );

      measurementData.handles.start3 = {
        x: eventData.currentPoints.image.x + lineLeng / 3,
        y: eventData.currentPoints.image.y - lineLeng / 3,
        drawnIndependently: false,
        highlight: true,
        active: true,
      };

      measurementData.handles.end3 = {
        x: eventData.currentPoints.image.x - lineLeng / 3,
        y: eventData.currentPoints.image.y + lineLeng / 3,
        drawnIndependently: false,
        highlight: true,
        active: true,
      };

      // toMoveHandle = measurementData.handles.end3;
      toMoveHandle = measurementData.handles.end2;
      this.hasIncomplete = false;
      doneMovingCallback = success => {
        // DoneMovingCallback for second measurement
        if (!success) {
          removeToolState(element, this.name, measurementData);

          return;
        }

        const eventType = EVENTS.MEASUREMENT_COMPLETED;
        const eventData = {
          toolName: this.name,
          toolType: this.name, // Deprecation notice: toolType will be replaced by toolName
          element,
          measurementData,
        };

        triggerEvent(element, eventType, eventData);
      };
    } else {
      measurementData = this.createNewMeasurement(eventData);
      addToolState(element, this.name, measurementData);
      toMoveHandle = measurementData.handles.end;
      // toMoveHandle = measurementData.handles.start;
    }

    // Associate this data with this imageId so we can render it and manipulate it
    cornerstone.updateImage(element);
    //moves as the whole element
    moveNewHandle(
      eventData,
      this.name,
      measurementData,
      toMoveHandle,
      this.options,
      interactionType,
      doneMovingCallback
    );
  }
  //if modified
  onMeasureModified(ev) {
    const { element } = ev.detail;
    const image = cornerstone.getEnabledElement(element).image;
    const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

    if (ev.detail.toolName !== this.name) {
      return;
    }
    const data = ev.detail.measurementData;

    // Update textbox stats
    if (data.invalidated === true) {
      if (data.diameter) {
        this.throttledUpdateCachedStats(image, element, data);
      } else {
        this.updateCachedStats(image, element, data);
      }
    }

    data.value = this.textBoxText(data, rowPixelSpacing, colPixelSpacing);
  }

  //function to get text data and undefined exception handling, returns array

  textBoxText({ diameter, rAngle }, rowPixelSpacing, colPixelSpacing) {
    const textLines = [];

    if (diameter === undefined || isNaN(diameter)) {
      return textLines;
    }

    if (rAngle === undefined || isNaN(rAngle)) {
      return textLines;
    }

    let suffixLength = 'mm';
    const suffixAngle =
      !rowPixelSpacing || !colPixelSpacing ? ' (isotropic)' : '';

    if (!rowPixelSpacing || !colPixelSpacing) {
      suffixLength = 'pixels';
    }

    const dim = `Diameter: ${diameter.toFixed(2)} ${suffixLength}`;
    const angle = `TPA: ${rAngle}\u00B0${suffixAngle}`;

    if (diameter) {
      textLines.push(dim);
    }

    if (rAngle) {
      textLines.push(angle);
    }

    return textLines;
  }

  //different types of tool event, like activaed/passive etc

  activeCallback(element) {
    this.onMeasureModified = this.onMeasureModified.bind(this);
    element.addEventListener(
      EVENTS.MEASUREMENT_MODIFIED,
      this.onMeasureModified
    );
  }

  passiveCallback(element) {
    this.onMeasureModified = this.onMeasureModified.bind(this);
    element.addEventListener(
      EVENTS.MEASUREMENT_MODIFIED,
      this.onMeasureModified
    );
  }

  enabledCallback(element) {
    element.removeEventListener(
      EVENTS.MEASUREMENT_MODIFIED,
      this.onMeasureModified
    );
  }

  disabledCallback(element) {
    element.removeEventListener(
      EVENTS.MEASUREMENT_MODIFIED,
      this.onMeasureModified
    );
  }
}
