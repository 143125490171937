/* eslint no-loop-func: 0 */ // --> OFF
import csTools, {
  importInternal,
  getToolState,
  toolColors,
} from 'cornerstone-tools';
import updatePerpendicularLineHandles from './utils/updatePerpendicularLineHandles.js';
import drawVhsMarker from './utils/drawVhsLine.js';
const getPixelSpacing = importInternal('util/getPixelSpacing');

const getNewContext = importInternal('drawing/getNewContext');
const draw = importInternal('drawing/draw');
const setShadow = importInternal('drawing/setShadow');
const drawLine = importInternal('drawing/drawLine');
const drawHandles = importInternal('drawing/drawHandles');
const drawLinkedTextBox = importInternal('drawing/drawLinkedTextBox');
const toolStyle = csTools.toolStyle;

export default function(evt) {
  const eventData = evt.detail;
  const { element, canvasContext, image } = eventData;
  const {
    handleRadius,
    hideHandlesIfMoving,
    renderDashed,
  } = this.configuration;

  const lineDash = csTools.getModule('globalConfiguration').configuration
    .lineDash;

  // If we have no toolData for this element, return immediately as there is nothing to do
  const toolData = getToolState(element, this.name);

  if (!toolData) {
    return;
  }
  // console.log(toolData);

  const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

  // LT-29 Disable Target Measurements when pixel spacing is not available
  // if (!rowPixelSpacing || !colPixelSpacing) {
  //   return;
  // }

  // We have tool data for this element - iterate over each one and draw it
  const context = getNewContext(canvasContext.canvas);

  let color;

  const lineWidth = toolStyle.getToolWidth();

  // for (let i = 0; i < toolData.data.length; i++) {
  for (let i = 0; i < 1; i++) {
    const data = toolData.data[i];
    if (data) {
      if (data.visible === false) {
        continue;
      }

      color = toolColors.getColorIfActive(data);

      // Calculate the data measurements
      if (data.invalidated === true) {
        if (data.longestDiameter && data.shortestDiameter) {
          this.throttledUpdateCachedStats(image, element, data);
        } else {
          this.updateCachedStats(image, element, data);
        }
      }

      draw(context, context => {
        // Configurable shadow
        setShadow(context, this.configuration);

        const {
          start,
          end,
          perpendicularStart,
          perpendicularEnd,
          textBox,
          startMarker,
          endMarker,
        } = data.handles;

        const lineOptions = { color };
        const blueLine = { color: '#20a5d6' };

        const strokeWidth = lineWidth;
        const perpendicularLineOptions = { color, strokeWidth };

        if (renderDashed) {
          lineOptions.lineDash = lineDash;
          perpendicularLineOptions.lineDash = lineDash;
        }

        // Draw the measurement line
        drawLine(context, element, start, end, lineOptions);

        // Draw perpendicular line

        updatePerpendicularLineHandles(eventData, data);

        drawLine(
          context,
          element,
          perpendicularStart,
          perpendicularEnd,
          perpendicularLineOptions
        );

        if (data.complete) {
          const tes = eventData.viewport.scale;
          function calculateDistance(x1, y1, x2, y2) {
            const deltaX = x2 - x1;
            const deltaY = y2 - y1;
            const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
            return distance;
          }

          const len1 = calculateDistance(start.x, start.y, end.x, end.y);
          const len2 = calculateDistance(
            perpendicularStart.x,
            perpendicularStart.y,
            perpendicularEnd.x,
            perpendicularEnd.y
          );
          drawVhsMarker(
            context,
            element,
            startMarker,
            // { x: startMarker.x + 25, y: startMarker.y + 25 },
            endMarker,
            // { x: startMarker.x, y: startMarker.y + data.dis },
            blueLine,
            len1 * tes,
            len2 * tes
          );
          data.isCreating = false;

          if (data.text) {
            var textBoxText =
              'VHS: ' +
              (Number(data.text) + Number(data.text1)) +
              ' (' +
              data.text +
              '+' +
              data.text1 +
              ')';
            // if (textBoxText !== data.text) {

            // }
          } else textBoxText = '[Click to add score]';
          data.handles.textMarker = data.handles.endMarker;

          drawLinkedTextBox(
            context,
            element,
            data.handles.textMarker,
            textBoxText,
            data.handles,
            textBoxAnchorPoints,
            color,
            lineWidth,
            10,
            true
          );
          function textBoxAnchorPoints(handles) {
            return [handles.endMarker];
          }
        }

        // Draw the handles
        const handleOptions = {
          color,
          handleRadius,
          drawHandlesIfActive: false,
          hideHandlesIfMoving,
        };

        // Draw the handles
        if (this.configuration.drawHandles) {
          drawHandles(context, eventData, data.handles, handleOptions);
        }
        // Draw the textbox
        // Move the textbox slightly to the right and upwards
        // So that it sits beside the length tool handle
        const xOffset = 10;

        const textLines = getTextBoxText(
          data,
          rowPixelSpacing,
          colPixelSpacing
        );

        drawLinkedTextBox(
          context,
          element,
          textBox,
          textLines,
          data.handles,
          textBoxAnchorPoints,
          color,
          lineWidth,
          xOffset,
          true
        );
        function textBoxAnchorPoints(handles) {
          return [handles.endMarker];
        }
      });
    }
  }
}

const getTextBoxText = (data, rowPixelSpacing, colPixelSpacing) => {
  let suffix = ' mm';

  if (!rowPixelSpacing || !colPixelSpacing) {
    suffix = ' pixels';
  }

  const lengthText = ` L ${data.longestDiameter}${suffix}`;
  const widthText = ` W ${data.shortestDiameter}${suffix}`;

  const { labels } = data;

  if (labels && Array.isArray(labels)) {
    return [...labels, lengthText, widthText];
  }

  return [lengthText, widthText];
};
