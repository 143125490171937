// import external from './../externalModules.js';
import cornerstone from 'cornerstone-core';
import csTools, { importInternal } from 'cornerstone-tools';
// import path from './path.js';
const path = importInternal('drawing/path');

/**
 * Draw a line between `start` and `end`.
 *
 * @public
 * @method drawLine
 * @memberof Drawing
 *
 * @param {CanvasRenderingContext2D} context
 * @param {HTMLElement} element - The DOM Element to draw on
 * @param {Object} start - `{ x, y } in either pixel or canvas coordinates.
 * @param {Object} end - `{ x, y }` in either pixel or canvas coordinates.
 * @param {Object} options - See {@link path}
 * @param {String} llength
 * @param {String} sLength
 * @param {String} [coordSystem='pixel'] - Can be "pixel" (default) or "canvas". The coordinate
 *     system of the points passed in to the function. If "pixel" then cornerstone.pixelToCanvas
 *     is used to transform the points from pixel to canvas coordinates.
 * @returns {undefined}
 */
export default function drawVhsMarker(
  context,
  element,
  start,
  end,
  options,
  lLength,
  sLength,
  a,
  b,
  coordSystem = 'pixel'
) {
  path(context, options, context => {
    if (coordSystem === 'pixel') {
      start = cornerstone.pixelToCanvas(element, start);
      end = cornerstone.pixelToCanvas(element, end);
    }
    function alignLine(start, end, value) {
      const deltaX = end.x - start.x;
      const deltaY = end.y - start.y;
      const length = Math.sqrt(deltaX ** 2 + deltaY ** 2); // Calculate length

      const x_new = start.x + value * deltaX;
      const y_new = start.y + value * deltaY;

      return { x: x_new, y: y_new, length };
    }
    const line1 = alignLine(start, end, 0.4);
    const line2 = alignLine(start, end, 0.6);
    const perpendicularDir = {
      x: end.y - start.y,
      y: -(end.x - start.x),
    };
    let cLine1 = line1;
    let cLine2 = line2;
    // if (coordSystem === 'pixel') {
    //   cLine1 = cornerstone.pixelToCanvas(element, line1);
    //   cLine2 = cornerstone.pixelToCanvas(element, line2);
    // }

    // Calculate the end point of the third line
    const thirdLineEnd = {
      x:
        cLine2.x +
        perpendicularDir.x *
          (lLength /
            Math.sqrt(perpendicularDir.x ** 2 + perpendicularDir.y ** 2)),
      y:
        cLine2.y +
        perpendicularDir.y *
          (lLength /
            Math.sqrt(perpendicularDir.x ** 2 + perpendicularDir.y ** 2)),
    };
    const secondLineEnd = {
      x:
        cLine1.x +
        perpendicularDir.x *
          (sLength /
            Math.sqrt(perpendicularDir.x ** 2 + perpendicularDir.y ** 2)),
      y:
        cLine1.y +
        perpendicularDir.y *
          (sLength /
            Math.sqrt(perpendicularDir.x ** 2 + perpendicularDir.y ** 2)),
    };
    let convertedLine1 = line1;
    let convertedSecondLineEnd = secondLineEnd;
    let convertedLine2 = line2;
    let convertedThirdLineEnd = thirdLineEnd;

    if (coordSystem === 'pixel') {
      convertedLine1 = cornerstone.pixelToCanvas(element, line1);
      convertedSecondLineEnd = cornerstone.pixelToCanvas(
        element,
        secondLineEnd
      );
      convertedLine2 = cornerstone.pixelToCanvas(element, line2);
      convertedThirdLineEnd = cornerstone.pixelToCanvas(element, thirdLineEnd);
    }

    context.moveTo(start.x, start.y);
    context.lineTo(end.x, end.y);

    context.moveTo(line1.x, line1.y);
    context.lineTo(secondLineEnd.x, secondLineEnd.y);

    context.moveTo(line2.x, line2.y);
    context.lineTo(thirdLineEnd.x, thirdLineEnd.y);
    // context.moveTo(convertedLine1.x, convertedLine1.y);
    // context.lineTo(convertedSecondLineEnd.x, convertedSecondLineEnd.y);

    // context.moveTo(convertedLine2.x, convertedLine2.y);
    // context.lineTo(convertedThirdLineEnd.x, convertedThirdLineEnd.y);

    // Log the lengths of the two perpendicular lines
  });
}
