// import external from './../../../externalModules.js';
import cornerstone from 'cornerstone-core';
import {
  importInternal,
  getToolState,
  toolColors,
  removeToolState,
  addToolState,
} from 'cornerstone-tools';

export default function getDoubleClick(evt) {
  const eventData = evt.detail;
  const { element, currentPoints } = eventData;
  let data;

  function doneChangingTextCallback(data, updatedText, deleteTool) {
    if (deleteTool === true) {
      removeToolState(element, this.name, data);
    } else {
      data.text = updatedText;
    }

    data.active = false;
    // console.log(data.text, updatedText);
    cornerstone.updateImage(element);
  }
  function doneChangingTextCallback1(data, updatedText, deleteTool) {
    if (deleteTool === true) {
      removeToolState(element, this.name, data);
    } else {
      data.text1 = updatedText;
    }

    data.active = false;
    // console.log(data.text, updatedText);
    cornerstone.updateImage(element);
  }

  const config = this.configuration;
  const coords = currentPoints.canvas;
  const toolData = getToolState(element, this.name);

  // Now check to see if there is a handle we can move
  if (!toolData) {
    return;
  }

  for (let i = 0; i < toolData.data.length; i++) {
    data = toolData.data[i];
    // if (this.pointNearTool(element, data, coords)) {
    if (data) {
      data.active = true;
      cornerstone.updateImage(element);

      // Allow relabelling via a callback
      config.changeTextCallback(data, eventData, doneChangingTextCallback);
      config.changeTextCallback1(data, eventData, doneChangingTextCallback1);
      evt.stopImmediatePropagation();
      evt.preventDefault();
      evt.stopPropagation();

      return;
    }
  }
}

/**
 * @callback doneChangingTextCallback
 * @param {Object} data
 * @param {string} text - The new text
 */
