import TPLOTool from './TPLOTool';
import csTools from 'cornerstone-tools';

/**
 *
 * @param {object} configuration
 * @param {Object|Array} configuration.csToolsConfig
 */
export default function init({ servicesManager, configuration = {} }) {
  csTools.addTool(TPLOTool, {
    name: 'TPLOTool',
  });
}
