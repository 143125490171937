import csTools from 'cornerstone-tools';
import {
  importInternal,
  getToolState,
  toolColors,
  removeToolState,
  addToolState,
} from 'cornerstone-tools';
import cornerstoneMath from 'cornerstone-math';
import cornerstone from 'cornerstone-core';
const draw = importInternal('drawing/draw');
const drawCircle = importInternal('drawing/drawCircle');
const BaseAnnotationTool = importInternal('base/BaseAnnotationTool');
const throttle = importInternal('util/throttle');
const textStyle = csTools.textStyle;
const toolStyle = csTools.toolStyle;
const moveNewHandle = importInternal('manipulators/moveNewHandle');
const getNewContext = importInternal('drawing/getNewContext');
const setShadow = importInternal('drawing/setShadow');
const drawLine = importInternal('drawing/drawLine');
const drawHandles = importInternal('drawing/drawHandles');
const drawLinkedTextBox = importInternal('drawing/drawLinkedTextBox');
const lineSegDistance = importInternal('util/lineSegDistance');
const roundToDecimal = importInternal('util/roundToDecimal');
const EVENTS = csTools.EVENTS;
const triggerEvent = importInternal('util/triggerEvent');
const getPixelSpacing = importInternal('util/getPixelSpacing');

/**
 * @public
 * @class HIPDTool
 * @memberof Tools.Annotation
 * @classdesc Tool for measuring the hip angle
 * @extends Tools.Base.BaseAnnotationTool
 */
export default class HIPDTool extends BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: 'HIPD',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      // svgCursor: cobbAngleCursor,
      configuration: {
        drawHandles: true,
        drawHandlesOnHover: false,
        hideHandlesIfMoving: false,
        renderDashed: false,
      },
    };

    super(props, defaultProps);
    this.hasIncomplete = false;
    this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 110);
  }
  createNewMeasurement(eventData) {
    // const toolData = getToolState(eventData.element, this.name);
    // if (toolData && toolData.data && toolData.data.length > 0) {
    //   return null;
    // }
    // Create the measurement data for this tool with the end handle activated
    this.hasIncomplete = true;
    //prevent having morethan one annotation/drawingper tool

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      complete: false,
      value: '',
      firstLine: {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        highlight: true,
        active: false,
        completed: false,
      },
      secondLine: {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        highlight: true,
        active: false,
        completed: false,
      },
      isCircleComplete: false,
      handles: {
        start: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        end: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
        start2: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
          drawnIndependently: true,
        },
        end2: {
          x: eventData.currentPoints.image.x + 1,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
          drawnIndependently: true,
        },
        start3: {
          x: '',
          y: '',
          highlight: true,
          active: false,
          completed: false,
        },

        start4: {
          x: '',
          y: '',
          highlight: true,
          active: false,
          completed: false,
        },
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
        textBox2: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
    };
  }
  /**
   *
   *
   * @param {*} element
   * @param {*} data
   * @param {*} coords
   * @returns {Boolean}
   */
  pointNearTool(element, data, coords) {
    if (data.visible === false) {
      return false;
    }

    if (this.hasIncomplete) {
      return false;
    }
    const seg1Near =
      lineSegDistance(element, data.handles.start, data.handles.end, coords) <
      25;
    const seg2Near =
      lineSegDistance(element, data.handles.start2, data.handles.end2, coords) <
      25;

    // console.log(seg1Near, seg2Near);

    return seg1Near || seg2Near;
  }

  updateCachedStats(image, element, data) {
    //destruct
    const { start, start2, start3, start4 } = data.handles;
    //grp the angles
    const triangle1 = {
      sideA: getSideLength(image, start, start3),
      sideB: getSideLength(image, start2, start),
      sideC: getSideLength(image, start2, start3),
    };

    const triangle2 = {
      sideA: getSideLength(image, start2, start4),
      sideB: getSideLength(image, start, start2),
      sideC: getSideLength(image, start, start4),
    };
    //its just the angle, keeping the namem convenntion from other tools, maybe change later?
    data.rAngle = calculateAngle(
      triangle1.sideA,
      triangle1.sideB,
      triangle1.sideC
    );
    data.rAngle2 = calculateAngle(
      triangle2.sideA,
      triangle2.sideB,
      triangle2.sideC
    );

    data.invalidated = false;

    function getSideLength(image, point1, point2) {
      const side = getSide(image, point1, point2);
      return length(side);
    }
    //cant use degree measurements
    function calculateAngle(sideA, sideB, sideC) {
      const angleRad = Math.acos(
        (Math.pow(sideA, 2) + Math.pow(sideB, 2) - Math.pow(sideC, 2)) /
          (2 * sideA * sideB)
      );
      return roundToDecimal(angleRad * (180 / Math.PI), 2);
    }
  }

  renderToolData(evt) {
    const eventData = evt.detail;
    const {
      handleRadius,
      drawHandlesOnHover,
      hideHandlesIfMoving,
      renderDashed,
    } = this.configuration;
    // If we have no toolData for this element, return immediately as there is nothing to do
    const toolData = getToolState(evt.currentTarget, this.name);
    const getDistance = cornerstoneMath.point.distance;

    if (!toolData) {
      return;
    }

    // We have tool data for this element - iterate over each one and draw it
    const context = getNewContext(eventData.canvasContext.canvas);

    const lineWidth = toolStyle.getToolWidth();
    const lineDash = csTools.getModule('globalConfiguration').configuration
      .lineDash;
    const font = textStyle.getFont();
    const { element } = evt.detail;
    const image = cornerstone.getEnabledElement(element).image;
    const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);
    // console.log(toolData);

    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];

      if (data.visible === false) {
        continue;
      }

      if (!data.value) {
        data.value = this.textBoxText(data, rowPixelSpacing, colPixelSpacing);
      }

      draw(context, context => {
        setShadow(context, this.configuration);

        // Differentiate the color of activation tool
        const color = toolColors.getColorIfActive(data);

        const lineOptions = { color };
        const blueLine = { color: '#20a5d6' };

        if (renderDashed) {
          lineOptions.lineDash = lineDash;
        }
        const startCanvas = cornerstone.pixelToCanvas(
          element,
          data.handles.start
        );

        const endCanvas = cornerstone.pixelToCanvas(element, data.handles.end);

        //inbuilt function
        const radius = getDistance(startCanvas, endCanvas);
        drawCircle(
          context,
          element,
          data.handles.start,
          radius,
          lineOptions,
          'pixel'
        );

        if (data.complete) {
          const startCanvas1 = cornerstone.pixelToCanvas(
            element,
            data.handles.start2
          );

          const endCanvas1 = cornerstone.pixelToCanvas(
            element,
            data.handles.end2
          );
          const radius1 = getDistance(startCanvas1, endCanvas1);
          drawCircle(
            context,
            element,
            data.handles.start2,
            radius1,
            lineOptions,
            'pixel'
          );
          if (data.isCircleComplete) {
            drawLine(
              context,
              eventData.element,
              data.handles.start,
              data.handles.start2,
              lineOptions
            );

            var x1 = data.handles.start.x;
            var y1 = data.handles.start.y;
            var x2 = data.handles.start2.x;
            var y2 = data.handles.start2.y;

            function calculateEndpoint(x1, y1, x2, y2, length, angleDegrees) {
              // Calculate the direction vector of the original line
              const dx = x2 - x1;
              const dy = y2 - y1;
              // Normalize the direction vector
              const directionLength = Math.sqrt(dx ** 2 + dy ** 2);
              const normalizedDx = dx / directionLength;
              const normalizedDy = dy / directionLength;

              // Calculate the angle in radians for the new line
              const angleRadians = (angleDegrees * Math.PI) / 180;
              // Calculate the new direction vector
              const newDx =
                normalizedDx * Math.cos(angleRadians) -
                normalizedDy * Math.sin(angleRadians);
              const newDy =
                normalizedDx * Math.sin(angleRadians) +
                normalizedDy * Math.cos(angleRadians);

              // Scale the new direction vector to have the specified length
              const scaledDx = newDx * length;
              const scaledDy = newDy * length;

              // Calculate the endpoint (x3, y3) of the new line, ensuring it's above the original line
              const x = x2 + scaledDx;
              const y = y2 + scaledDy;
              const completed = true;

              return { x, y, completed };
            }

            function calculateDistance(x1, y1, x2, y2) {
              const deltaX = x2 - x1;
              const deltaY = y2 - y1;
              const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
              return distance;
            }
            const len = calculateDistance(x1, y1, x2, y2);

            const angleDegrees = 75;
            const firstLine = calculateEndpoint(
              x2,
              y2,
              x1,
              y1,
              len,
              angleDegrees
            );
            const secondLine = calculateEndpoint(x1, y1, x2, y2, len, -75);

            if (!data.handles.start3.completed) {
              data.handles.start3 = firstLine;
            }
            if (!data.handles.start4.completed) {
              data.handles.start4 = secondLine;
            }
            data.firstLine = firstLine;
            if (1) {
              drawLine(
                context,
                eventData.element,
                data.handles.start2,
                data.handles.start4,
                blueLine
              );
              drawLine(
                context,
                eventData.element,
                data.handles.start,
                data.handles.start3,
                blueLine
              );
            }
          }
          data.isCircleComplete = true;
        }

        // Draw the handles
        const handleOptions = {
          color,
          handleRadius,
          drawHandlesIfActive: drawHandlesOnHover,
          hideHandlesIfMoving,
        };

        if (this.configuration.drawHandles) {
          drawHandles(context, eventData, data.handles, handleOptions);
        }

        // Draw the text
        context.fillStyle = color;

        const text = data.value;
        const text2 = data.value2;

        if (!data.handles.textBox.hasMoved) {
          const textCoords = {
            x: data.handles.start3.x,
            y: data.handles.start3.y,
          };

          context.font = font;
          data.handles.textBox.x = textCoords.x;
          data.handles.textBox.y = textCoords.y;
        }
        if (!data.handles.textBox2.hasMoved) {
          const textCoords = {
            x: data.handles.start4.x,
            y: data.handles.start4.y,
          };

          context.font = font;
          data.handles.textBox2.x = textCoords.x;
          data.handles.textBox2.y = textCoords.y;
        }

        drawLinkedTextBox(
          context,
          eventData.element,
          data.handles.textBox,
          text,
          data.handles,
          textBoxAnchorPoints,
          color,
          lineWidth,
          10,
          true
        );
        drawLinkedTextBox(
          context,
          eventData.element,
          data.handles.textBox2,
          text2,
          data.handles,
          textBoxAnchorPoints2,
          color,
          lineWidth,
          10,
          true
        );
      });
    }

    function textBoxAnchorPoints(handles) {
      return [handles.start3];
    }
    function textBoxAnchorPoints2(handles) {
      return [handles.start4];
    }
  }

  getIncomplete(element) {
    const toolState = getToolState(element, this.name);

    if (toolState && Array.isArray(toolState.data)) {
      return toolState.data.find(({ complete }) => complete === false);
    }
  }

  addNewMeasurement(evt, interactionType) {
    evt.preventDefault();
    evt.stopPropagation();

    const eventData = evt.detail;

    let measurementData;
    let toMoveHandle;
    let doneMovingCallback = success => {
      // DoneMovingCallback for first measurement.
      if (!success) {
        removeToolState(element, this.name, measurementData);

        return;
      }
      const eventType = EVENTS.MEASUREMENT_COMPLETED;
      const eventData = {
        toolName: this.name,
        toolType: this.name, // Deprecation notice: toolType will be replaced by toolName
        element,
        measurementData,
      };

      triggerEvent(element, eventType, eventData);
    };

    // Search for incomplete measurements
    const element = evt.detail.element;
    const pendingMeasurement = this.getIncomplete(element);

    if (pendingMeasurement) {
      measurementData = pendingMeasurement;
      measurementData.complete = true;
      measurementData.handles.start2 = {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        drawnIndependently: false,
        highlight: true,
        active: false,
      };
      measurementData.handles.end2 = {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        drawnIndependently: false,
        highlight: true,
        active: true,
      };
      measurementData.handles.start3 = {
        x: measurementData.handles.end2.x,
        y: measurementData.handles.end2.y,
        drawnIndependently: false,
        highlight: true,
        active: true,
      };
      measurementData.handles.start4 = {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        drawnIndependently: false,
        highlight: true,
        active: true,
      };
      toMoveHandle = measurementData.handles.end2;
      this.hasIncomplete = false;
      doneMovingCallback = success => {
        // DoneMovingCallback for second measurement
        if (!success) {
          removeToolState(element, this.name, measurementData);

          return;
        }

        const eventType = EVENTS.MEASUREMENT_COMPLETED;
        const eventData = {
          toolName: this.name,
          toolType: this.name, // Deprecation notice: toolType will be replaced by toolName
          element,
          measurementData,
        };

        triggerEvent(element, eventType, eventData);
      };
    } else {
      measurementData = this.createNewMeasurement(eventData);
      addToolState(element, this.name, measurementData);
      toMoveHandle = measurementData.handles.end;
    }

    // Associate this data with this imageId so we can render it and manipulate it
    cornerstone.updateImage(element);

    moveNewHandle(
      eventData,
      this.name,
      measurementData,
      toMoveHandle,
      this.options,
      interactionType,
      doneMovingCallback
    );
  }

  onMeasureModified(ev) {
    const { element } = ev.detail;
    const image = cornerstone.getEnabledElement(element).image;
    const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

    if (ev.detail.toolName !== this.name) {
      return;
    }
    const data = ev.detail.measurementData;

    // Update textbox stats
    if (data.invalidated === true) {
      if (data.rAngle) {
        this.throttledUpdateCachedStats(image, element, data);
      } else {
        this.updateCachedStats(image, element, data);
      }
    }

    data.value = this.textBoxText(data, rowPixelSpacing, colPixelSpacing);
    data.value2 = this.textBoxText2(data, rowPixelSpacing, colPixelSpacing);
  }

  textBoxText({ rAngle }, rowPixelSpacing, colPixelSpacing) {
    if (rAngle === undefined) {
      return '';
    }
    if (Number.isNaN(rAngle)) {
      return '';
    }

    const suffix = !rowPixelSpacing || !colPixelSpacing ? ' (isotropic)' : '';

    return `${rAngle}\u00B0${suffix}`;
  }
  textBoxText2({ rAngle2 }, rowPixelSpacing, colPixelSpacing) {
    if (rAngle2 === undefined) {
      return '';
    }
    if (Number.isNaN(rAngle2)) {
      return '';
    }

    const suffix = !rowPixelSpacing || !colPixelSpacing ? ' (isotropic)' : '';

    return `${rAngle2}\u00B0${suffix}`;
  }

  activeCallback(element) {
    this.onMeasureModified = this.onMeasureModified.bind(this);
    element.addEventListener(
      EVENTS.MEASUREMENT_MODIFIED,
      this.onMeasureModified
    );
  }

  passiveCallback(element) {
    this.onMeasureModified = this.onMeasureModified.bind(this);
    element.addEventListener(
      EVENTS.MEASUREMENT_MODIFIED,
      this.onMeasureModified
    );
  }

  enabledCallback(element) {
    element.removeEventListener(
      EVENTS.MEASUREMENT_MODIFIED,
      this.onMeasureModified
    );
  }

  disabledCallback(element) {
    element.removeEventListener(
      EVENTS.MEASUREMENT_MODIFIED,
      this.onMeasureModified
    );
  }
}
function length(vector) {
  return Math.sqrt(Math.pow(vector.x, 2) + Math.pow(vector.y, 2));
}

function getSide(image, handleEnd, handleStart) {
  const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

  return {
    x: (handleEnd.x - handleStart.x) * (colPixelSpacing || 1),
    y: (handleEnd.y - handleStart.y) * (rowPixelSpacing || 1),
  };
}
