import React from 'react';
import './TPLODialog.css';
import PropTypes from 'prop-types';

const VHSDialog = ({ onClose }) => {
  return (
    <div className="list-container">
      <button className="close-button" onClick={onClose}>
        ×
      </button>

      <h1>VHS</h1>
      <ol className="ordered-list">
        <li>Draw L axis of the heart.</li>
        <li>Adjust the S axis.</li>
        <li>
          Click the cranial edge of the T4 vertebrae to place a gauge for the L
          and S measurements.
        </li>
        <li>
          Adjust the new handles as necessary to extend the measurements across
          the vertebrae.
        </li>
        <li>Double click the text labeled "Click to add score".</li>
        <li>
          Enter the number of vertebrae that fall within each length on the
          gauge.
        </li>
      </ol>
    </div>
  );
};
VHSDialog.propTypes = {
  onClose: PropTypes.func,
};

export default VHSDialog;
