import init from './init';

export default {
  /**
   * Only required property. Should be a unique value across all extensions.
   */
  id: 'tplo-tool',

  preRegistration({ servicesManager, configuration = {} }) {
    init({ servicesManager, configuration });
  },
};
