import React from 'react';
import './TPLODialog.css';
import PropTypes from 'prop-types';

const HIPDDialog = ({ onClose }) => {
  return (
    <div className="list-container">
      <button className="close-button" onClick={onClose}>
        ×
      </button>

      <h1>HIPD</h1>
      <ol className="ordered-list">
        <li>Draw a circle around the femoral heads on each side.</li>
        <li>
          Adjust the end of the line formed from each femoral head so that it
          touches the craniolateral aspect of the dorsal acetabular rim of the
          corresponding side.
        </li>
      </ol>
    </div>
  );
};
HIPDDialog.propTypes = {
  onClose: PropTypes.func,
};

export default HIPDDialog;
