import VHSTool from './VHSTool';
import csTools from 'cornerstone-tools';

/**
 *
 * @param {object} configuration
 * @param {Object|Array} configuration.csToolsConfig
 */
export default function init({ servicesManager, configuration = {} }) {
  csTools.addTool(VHSTool, {
    name: 'VHSTool',
  });
}
