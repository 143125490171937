import React from 'react';
import './TPLODialog.css';
import PropTypes from 'prop-types';

const TPLODialog = ({ onClose }) => {
  return (
    <div className="list-container">
      <button className="close-button" onClick={onClose}>
        ×
      </button>

      <h1>TPLO</h1>
      <ol className="ordered-list">
        <li>Draw a circle around the talus.</li>
        <li>
          Click the center of the intercondylar eminences to draw the functional
          tibial axis and click again to place the line.
        </li>
        <li>
          Extend the newly formed line to caudal and cranial aspects of the
          medial tibial plateau. The tibial plateau angle will be displayed, as
          well as the minimum cut diameter to reach the furthest aspect.
        </li>
      </ol>
    </div>
  );
};
TPLODialog.propTypes = {
  onClose: PropTypes.func,
};

export default TPLODialog;
