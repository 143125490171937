// import external from './../../../externalModules.js';
import csTools from 'cornerstone-tools';
// import anyHandlesOutsideImage from './../../../manipulators/anyHandlesOutsideImage.js';
import {
  importInternal,
  getToolState,
  toolColors,
  removeToolState,
  addToolState,
} from 'cornerstone-tools';
// import triggerEvent from '../../../util/triggerEvent.js';
const triggerEvent = importInternal('util/triggerEvent');
// import getActiveTool from '../../../util/getActiveTool';
const getActiveTool = importInternal('util/getActiveTool');
import VHSTool from '../VHSTool';
import updatePerpendicularLineHandles from './utils/updatePerpendicularLineHandles.js';
import getIncomplete from './getIncomplete.js';
const moveNewHandle = importInternal('manipulators/moveNewHandle');
const anyHandlesOutsideImage = importInternal(
  'manipulators/anyHandlesOutsideImage'
);
const getPixelSpacing = importInternal('util/getPixelSpacing');

const EVENTS = csTools.EVENTS;

export default function(evt, interactionType) {
  evt.preventDefault();
  evt.stopPropagation();
  const eventData = evt.detail;
  const { element, image, buttons } = eventData;

  const config = this.configuration;

  // if (checkPixelSpacing(image)) {
  //   return;
  // }

  let measurementData = this.createNewMeasurement(eventData);
  const pendingMeasurement = this.getIncomplete(element);
  let toMoveHandle;
  let doneMovingCallback = success => {
    // DoneMovingCallback for first measurement.
    if (!success) {
      removeToolState(element, this.name, measurementData);

      return;
    }
    const eventType = EVENTS.MEASUREMENT_COMPLETED;
    const eventData = {
      toolName: this.name,
      toolType: this.name, // Deprecation notice: toolType will be replaced by toolName
      element,
      measurementData,
    };

    triggerEvent(element, eventType, eventData);
  };

  const doneCallback = () => {
    measurementData.active = false;
    cornerstone.updateImage(element);
  };

  // Associate this data with this imageId so we can render it and manipulate it
  addToolState(element, this.name, measurementData);
  cornerstone.updateImage(element);

  const timestamp = new Date().getTime();
  const { end, perpendicularStart } = measurementData.handles;
  if (pendingMeasurement) {
    //why? isnt it already there?
    measurementData = pendingMeasurement;
    measurementData.complete = true;
    measurementData.handles.startMarker = {
      x: eventData.currentPoints.image.x,
      y: eventData.currentPoints.image.y,
      drawnIndependently: false,
      // highlight: true,
      active: false,
    };
    const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);
    if (rowPixelSpacing < 0.1) {
      var placeHolder = 10 / rowPixelSpacing;
    } else placeHolder = 400;

    measurementData.handles.endMarker = {
      x: eventData.currentPoints.image.x,
      y: eventData.currentPoints.image.y + placeHolder,
      drawnIndependently: false,
      highlight: true,
      active: true,
    };
    toMoveHandle = measurementData.handles.endMarker;
    // this.hasIncomplete = false;
    doneMovingCallback = success => {
      // DoneMovingCallback for second measurement
      if (!success) {
        removeToolState(element, this.name, measurementData);

        return;
      }
      const eventType = EVENTS.MEASUREMENT_COMPLETED;
      const eventData = {
        toolName: this.name,
        toolType: this.name, // Deprecation notice: toolType will be replaced by toolName
        element,
        measurementData,
      };

      triggerEvent(element, eventType, eventData);
    };
  } else {
    measurementData = this.createNewMeasurement(eventData);
    addToolState(element, this.name, measurementData);
    toMoveHandle = measurementData.handles.end;
    // toMoveHandle = measurementData.handles.start;
  }

  moveNewHandle(
    eventData,
    this.name,
    measurementData,
    end,
    {},
    interactionType,
    success => {
      if (!success) {
        removeToolState(element, this.name, measurementData);

        return;
      }
      const { handles, longestDiameter, shortestDiameter } = measurementData;
      const hasHandlesOutside = anyHandlesOutsideImage(eventData, handles);
      const longestDiameterSize = parseFloat(longestDiameter) || 0;
      const shortestDiameterSize = parseFloat(shortestDiameter) || 0;
      const isTooSmal = longestDiameterSize < 1 || shortestDiameterSize < 1;
      const isTooFast = new Date().getTime() - timestamp < 150;

      if (hasHandlesOutside || isTooSmal || isTooFast) {
        // Delete the measurement
        measurementData.cancelled = true;
        removeToolState(element, this.name, measurementData);
      } else {
        // Set lesionMeasurementData Session
        config.getMeasurementLocationCallback(
          measurementData,
          eventData,
          doneCallback
        );
      }

      // Update perpendicular line and disconnect it from the long-line
      updatePerpendicularLineHandles(eventData, measurementData);
      perpendicularStart.locked = false;

      measurementData.invalidated = true;

      cornerstone.updateImage(element);

      const activeTool = getActiveTool(element, buttons, interactionType);

      if (activeTool instanceof VHSTool) {
        activeTool.updateCachedStats(image, element, measurementData);
      }
      const modifiedEventData = {
        toolName: this.name,
        toolType: this.name, // Deprecation notice: toolType will be replaced by toolName
        element,
        measurementData,
      };

      triggerEvent(element, EVENTS.MEASUREMENT_MODIFIED, modifiedEventData);
      triggerEvent(element, EVENTS.MEASUREMENT_COMPLETED, modifiedEventData);
    }
  );
}

const checkPixelSpacing = image => {
  const imagePlane = cornerstone.metaData.get(
    'imagePlaneModule',
    image.imageId
  );
  let rowPixelSpacing = image.rowPixelSpacing;
  let colPixelSpacing = image.columnPixelSpacing;

  if (imagePlane) {
    rowPixelSpacing =
      imagePlane.rowPixelSpacing || imagePlane.rowImagePixelSpacing;
    colPixelSpacing =
      imagePlane.columnPixelSpacing || imagePlane.colImagePixelSpacing;
  }
  console.log(imagePlane);

  // LT-29 Disable Target Measurements when pixel spacing is not available
  return !rowPixelSpacing || !colPixelSpacing;
};
