import { importInternal } from 'cornerstone-tools';
const BaseAnnotationTool = importInternal('base/BaseAnnotationTool');

const throttle = importInternal('util/throttle');
const getPixelSpacing = importInternal('util/getPixelSpacing');

import createNewMeasurement from './VHSTool/createNewMeasurement.js';
import pointNearTool from './VHSTool/pointNearTool.js';
import renderToolData from './VHSTool/renderToolData.js';
import addNewMeasurement from './VHSTool/addNewMeasurement.js';
import _moveCallback from './VHSTool/mouseMoveCallback.js';
import handleSelectedCallback from './VHSTool/handleSelectedCallback.js';
import handleSelectedMouseCallback from './VHSTool/handleSelectedMouseCallback.js';
import handleSelectedTouchCallback from './VHSTool/handleSelectedTouchCallback.js';
import calculateLongestAndShortestDiameters from './VHSTool/utils/calculateLongestAndShortestDiameters';
import getIncomplete from './VHSTool/getIncomplete.js';
import getDoubleClick from './VHSTool/getDoubleClick.js';

const emptyLocationCallback = (measurementData, eventData, doneCallback) =>
  doneCallback();

const changeTextCallback = (data, eventData, doneChangingTextCallback) => {
  // eslint-disable-next-line no-alert
  doneChangingTextCallback(
    data,
    prompt('Enter the vertebrae count for the Long axis:')
  );
};
const changeTextCallback1 = (data, eventData, doneChangingTextCallback) => {
  // eslint-disable-next-line no-alert
  doneChangingTextCallback(
    data,
    prompt('Enter the vertebrae count for the Short axis:')
  );
};

/**
 * @public
 * @class VHSTool
 * @memberof Tools.Annotation
 * @classdesc Create and position an annotation that measures the
 * length and width of a region.
 * @extends Tools.Base.BaseAnnotationTool
 */

export default class VHSTool extends BaseAnnotationTool {
  constructor(props) {
    const defaultProps = {
      name: 'VHS',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      configuration: {
        changeMeasurementLocationCallback: emptyLocationCallback,
        getMeasurementLocationCallback: emptyLocationCallback,
        changeTextCallback: changeTextCallback,
        changeTextCallback1: changeTextCallback1,
        textBox: '',
        shadow: '',
        drawHandles: true,
        drawHandlesOnHover: true,
        hideHandlesIfMoving: false,
        renderDashed: false,
        additionalData: [],
      },
      // svgCursor: bidirectionalCursor,
    };

    super(props, defaultProps);
    this.hasIncomplete = false;

    this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 110);
    this.createNewMeasurement = createNewMeasurement.bind(this);
    this.pointNearTool = pointNearTool.bind(this);
    this.renderToolData = renderToolData.bind(this);
    this.addNewMeasurement = addNewMeasurement.bind(this);
    this._moveCallback = _moveCallback.bind(this);
    this.handleSelectedCallback = handleSelectedCallback.bind(this);
    this.handleSelectedMouseCallback = handleSelectedMouseCallback.bind(this);
    this.handleSelectedTouchCallback = handleSelectedTouchCallback.bind(this);
    this.getIncomplete = getIncomplete.bind(this);
    this.doubleClickCallback = getDoubleClick.bind(this);
  }

  updateCachedStats(image, element, data) {
    // Prevent updating other tools' data
    if (data.toolName !== this.name) {
      return;
    }

    const pixelSpacing = getPixelSpacing(image);
    const {
      longestDiameter,
      shortestDiameter,
    } = calculateLongestAndShortestDiameters(data, pixelSpacing);

    // Set measurement text to show lesion table
    data.longestDiameter = longestDiameter;
    data.shortestDiameter = shortestDiameter;
    data.invalidated = false;
  }
}
